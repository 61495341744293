import type { ContractAddressMap } from '@amply-app/sdk';
import { getContractAddress } from '@amply-app/sdk';
import { useMutation } from '@tanstack/react-query';
import { tokenMap } from '@ui/components/TokenInput/constants';
import { getZyfiData } from '@ui/utils';
import type { Fraction } from 'bi-fraction';
import { MaxUint256 } from 'ethers';

import { approveIfNeeded } from '../data/utils';
import { getAssetSetup, useChainData } from './useChainData';

export const useWithdrawMutation = () => {
  const {
    account,
    chainId,
    provider,
    connectorProvider,
    gasTokenSymbol,
    gasTokenAddress,
  } = useChainData();

  return useMutation(
    async ({
      amount,
      tokenSymbol,
      isMaxAmount,
    }: {
      amount: Fraction;
      tokenSymbol: keyof ContractAddressMap | 'zkCRO';
      isMaxAmount: boolean;
    }) => {
      if (!chainId || !account || !provider || !connectorProvider) return;

      const { browserSigner, sdk, assetAddress, poolAddress, decimals } =
        await getAssetSetup(connectorProvider, chainId, tokenSymbol);

      const addOneAmount = isMaxAmount
        ? amount.add(1).shl(Number(decimals)).quotient
        : amount.shl(Number(decimals)).quotient;
      const maxAmount = isMaxAmount
        ? MaxUint256
        : amount.shl(Number(decimals)).quotient;

      if (tokenSymbol === tokenMap.zkCRO) {
        // https://docs.aave.com/developers/periphery-contracts/wethgateway#withdraweth
        // The WETHGateway contract must have an approved token allowance to spend aWETH on behalf of the user
        const provider = sdk
          .getContractAddress('PoolAddressesProvider')
          .toLowerCase();
        const reservesData = await sdk
          .getUiPoolDataProviderV3Abi()
          .getReservesData(provider);
        const wzkCROAddress = getContractAddress(chainId, 'wzkCRO');
        const wzkACROAddress = reservesData[0].find(
          (item) => item[0] === wzkCROAddress,
        )?.aTokenAddress;
        const wzkACRO = sdk.getERC20(wzkACROAddress!, browserSigner);
        const gatewayAddress = getContractAddress(
          chainId,
          'WrappedTokenGatewayV3',
        );

        await approveIfNeeded({
          account,
          spender: gatewayAddress,
          amount: addOneAmount,
          token: wzkACRO,
          gasTokenAddress,
          gasTokenSymbol,
        });
      }
      if (gasTokenSymbol !== tokenMap.zkCRO) {
        if (tokenSymbol === tokenMap.zkCRO) {
          const wrappedTokenGateway =
            sdk.getWrappedTokenGatewayV3(browserSigner);
          const gasLimit = await wrappedTokenGateway.withdrawETH.estimateGas(
            poolAddress,
            maxAmount,
            account,
          );
          const functionData = wrappedTokenGateway.interface.encodeFunctionData(
            'withdrawETH',
            [poolAddress, maxAmount, account],
          );
          const data = await getZyfiData({
            gasLimit,
            fromAddress: account,
            toAddress: await wrappedTokenGateway.getAddress(),
            gasTokenAddress,
            functionData,
          });
          const tx = await browserSigner.sendTransaction(data.txData);
          return await tx.wait();
        } else {
          const l2Pool = sdk.getL2Pool(poolAddress, browserSigner);
          const gasLimit = await l2Pool[
            'withdraw(address,uint256,address)'
          ].estimateGas(assetAddress, addOneAmount, account);
          const functionData = l2Pool.interface.encodeFunctionData(
            'withdraw(address,uint256,address)',
            [assetAddress, addOneAmount, account],
          );
          const data = await getZyfiData({
            gasLimit,
            fromAddress: account,
            toAddress: await l2Pool.getAddress(),
            gasTokenAddress,
            functionData,
          });
          const tx = await browserSigner.sendTransaction(data.txData);
          return await tx.wait();
        }
      }

      let tx;

      if (tokenSymbol === tokenMap.zkCRO) {
        tx = await sdk
          .getWrappedTokenGatewayV3(browserSigner)
          .withdrawETH(poolAddress, maxAmount, account);
      } else {
        tx = await sdk
          .getL2Pool(poolAddress, browserSigner)
          ['withdraw(address,uint256,address)'](
            assetAddress,
            maxAmount,
            account,
          );
      }

      return await tx.wait();
    },
  );
};
