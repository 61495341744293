import { currentWallet } from '@amply-app/wallet';
import { useMutation } from '@tanstack/react-query';
import { usePaymaster } from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { tokenMap } from '@ui/components/TokenInput/constants';
import { getSDK, getZyfiData } from '@ui/utils';
import { BrowserProvider } from 'zksync-ethers';

import { useUserReservesData } from '../data/useUserReservesData';

const { useAccount, useProvider, useChainId, getConnector } = currentWallet;

export const useEModeMutation = () => {
  const refetchUseUserReservesData = useUserReservesData().refetch;
  const account = useAccount();
  const provider = useProvider();
  const chainId = useChainId();
  const connectorProvider = getConnector().provider;
  const [gasTokenSymbol, gasTokenAddress] = usePaymaster((s) => [
    s.token,
    s.tokenAddress,
  ]);
  return useMutation(
    async ({ categoryId }: { categoryId: number }) => {
      if (!chainId || !account || !provider || !connectorProvider) return;

      const browserSigner = await new BrowserProvider(
        connectorProvider,
      ).getSigner();
      const sdk = getSDK();

      const poolAddress = await sdk.getPoolAddressesProvider().getPool();

      if (gasTokenSymbol !== tokenMap.zkCRO) {
        const l2Pool = sdk.getL2Pool(poolAddress, browserSigner);
        const gasLimit = await l2Pool.setUserEMode.estimateGas(categoryId);
        const functionData = l2Pool.interface.encodeFunctionData(
          'setUserEMode',
          [categoryId],
        );
        const data = await getZyfiData({
          gasLimit,
          fromAddress: account,
          toAddress: await l2Pool.getAddress(),
          gasTokenAddress,
          functionData,
        });
        const tx = await browserSigner.sendTransaction(data.txData);
        return await tx.wait();
      }

      const tx = await sdk
        .getL2Pool(poolAddress, browserSigner)
        .setUserEMode(categoryId);

      return await tx.wait();
    },
    {
      onSuccess: () => refetchUseUserReservesData(),
    },
  );
};
