import {
  Box,
  Button,
  Flex,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useAllPoolsData } from '@ui/hooks/data';
import { useEModes, useUserEModeCategoryId } from '@ui/hooks/data/useEMode';
import { Fraction } from 'bi-fraction';
import React from 'react';

import { useEModeModal } from '../EModeModal/hooks';
import { TokenCard } from '.';

export const TokenCards = () => {
  const { data: poolsData } = useAllPoolsData();
  const openEModeModal = useEModeModal((s) => s.open);
  const { data: userEModeId } = useUserEModeCategoryId();
  const { data: eModes, isLoading } = useEModes();
  const label = eModes?.find(({ id }) => userEModeId?.eq(id))?.label;
  return (
    <Tabs>
      <TabList>
        <Tab flex={1}>Supply info</Tab>
        <Tab flex={1}>Borrow info</Tab>
      </TabList>
      <Flex mt={8} gap={2}>
        <Button
          variant={userEModeId?.neq(Fraction.ZERO) ? 'claim' : 'outline'}
          h={'30px'}
          onClick={openEModeModal}
          isLoading={!eModes || isLoading}
          flex={1}
        >
          E-Mode: {userEModeId?.neq(Fraction.ZERO) ? label : 'off'}
        </Button>
        <Button
          as={Link}
          href={'/markets/leverage-looping'}
          variant={'outline'}
          h={'30px'}
          flex={1}
        >
          Best yields
        </Button>
      </Flex>
      <TabPanels>
        <TabPanel>
          <Box my={4}>
            {poolsData?.map((tokenInfo) => (
              <TokenCard
                key={tokenInfo.symbol}
                tokenData={tokenInfo}
                category={'supply'}
              />
            ))}
          </Box>
        </TabPanel>
        <TabPanel>
          <Box my={4}>
            {poolsData?.map((tokenInfo) => (
              <TokenCard
                key={tokenInfo.symbol}
                tokenData={tokenInfo}
                category={'borrow'}
              />
            ))}
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
