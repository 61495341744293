import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { RAY_UNIT } from '@ui/config/sc';
import { RefetchInterval } from '@ui/config/ui';
import { useAllPoolsData } from '@ui/hooks/data';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';

import { checkZKCROAddress } from './utils';

export interface LeverageLoopingInfo {
  borrowSymbol: string;
  leverage: Fraction;
  maxLeverage: Fraction;
  supplyAPR: Fraction;
  borrowAPR: Fraction;
  netAPR: Fraction;
}

export type LeverageLooping = Record<string, LeverageLoopingInfo[]>;

export interface LeverageLoopingData {
  sortedKeys: string[];
  borrowSortedKeys: string[];
  leverageLooping: LeverageLooping;
}

export type PoolAPR = Record<
  string,
  { supplyAPR: Fraction; borrowAPR: Fraction }
>;

export const useLeverageLooping = (
  leveragePercent: Fraction,
): UseQueryResult<LeverageLoopingData> => {
  const { data: poolsData } = useAllPoolsData();
  const sdk = getSDK();
  const leverageLooping: LeverageLooping = {};

  return useQuery(
    ['useLeverageLooping', leveragePercent],
    async () => {
      if (!poolsData) return {};
      const poolAddress = await sdk.getPoolAddressesProvider().getPool();

      const poolAPR: PoolAPR = {};

      await Promise.all(
        poolsData.map(async (poolData) => {
          const data = await sdk
            .getL2Pool(poolAddress)
            .getReserveData(checkZKCROAddress(poolData.tokenAddress)!);
          const supplyAPR = new Fraction(data[2]).shr(RAY_UNIT);
          const borrowAPR = new Fraction(data[4]).shr(RAY_UNIT);
          poolAPR[poolData.symbol] = { supplyAPR, borrowAPR };
        }),
      );

      poolsData.forEach((supplyPool) => {
        const supplyAPR = poolAPR[supplyPool.symbol].supplyAPR;
        leverageLooping[supplyPool.symbol] = [];

        poolsData.forEach((borrowPool) => {
          const maxLeverage = new Fraction(Fraction.ONE).div(
            new Fraction(Fraction.ONE).sub(supplyPool.baseLTVasCollateral),
          );
          const leverage = leveragePercent.mul(maxLeverage);
          const borrowAPR = poolAPR[borrowPool.symbol].borrowAPR;

          const APR_MULTIPLIER = 10000;
          const supplyAPRPercentage = new Fraction(
            Number(supplyAPR.toFixed(4)) * APR_MULTIPLIER,
            APR_MULTIPLIER,
          );
          const borrowAPRPercentage = new Fraction(
            Number(borrowAPR.toFixed(4)) * APR_MULTIPLIER,
            APR_MULTIPLIER,
          );

          const netAPR = supplyAPRPercentage
            .mul(leverage)
            .sub(leverage.sub(Fraction.ONE).mul(borrowAPRPercentage));

          leverageLooping[supplyPool.symbol].push({
            borrowSymbol: borrowPool.symbol,
            leverage,
            maxLeverage,
            supplyAPR,
            borrowAPR,
            netAPR,
          });
        });
      });

      const leverageLoopingData: LeverageLoopingData = {
        sortedKeys: Object.keys(leverageLooping).sort((a, b) => {
          const maxNetAPR_A = Math.max(
            ...leverageLooping[a].map((info) => Number(info.netAPR.toFixed(4))),
          );
          const maxNetAPR_B = Math.max(
            ...leverageLooping[b].map((info) => Number(info.netAPR.toFixed(4))),
          );
          return maxNetAPR_B - maxNetAPR_A;
        }),
        leverageLooping,
        borrowSortedKeys: [],
      };
      const supplySortedKeys = leverageLoopingData.sortedKeys;
      leverageLooping[supplySortedKeys[0]].sort((a, b) =>
        a.netAPR.gt(b.netAPR) ? -1 : 1,
      );
      const borrowSortedKeys = leverageLooping[supplySortedKeys[0]].map(
        (info) => info.borrowSymbol,
      );
      Object.keys(leverageLooping).forEach((key) => {
        leverageLooping[key].sort(
          (a, b) =>
            borrowSortedKeys.indexOf(a.borrowSymbol) -
            borrowSortedKeys.indexOf(b.borrowSymbol),
        );
      });
      leverageLoopingData.borrowSortedKeys = borrowSortedKeys;
      return leverageLoopingData;
    },
    {
      enabled: !!poolsData,
      refetchInterval: RefetchInterval.Normal,
    },
  );
};
