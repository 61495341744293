import { ChainId } from '../types';
const ADDRESS_BY_CHAIN = {
    [ChainId.CRONOS_ZKEVM_MAINNET]: {
        CronosId: '0x7F4C61116729d5b27E5f180062Fdfbf32E9283E5',
        PoolAddressesProviderRegistry: '0xc8AFD4de7E50f59107a87DB82b5F1Eb2F41D4F0F',
        PreAmplyToken: '0xb58c0f5dc3F1FeE5f9907C5f737b3BEcCd476e61',
        Amply: '0x66b1E42B2D5bce3D701Efa3c86Cc334d80cEEfaB',
        AmplyVault: '0x40bCbB15AaFEf55b874382AEE80BdDd68f5CA23B',
        AmplyVesting: '0xbf32061bCA0dfC511DDF0a69efD95DDcF011076F',
        PoolAddressesProvider: '0xDA59Cc2Ee08ce5F830B1190bfed34415772Ddc57',
        AmplyProtocolDataProvider: '0x47656eb2A31094b348EBF458Eccb942d471324eD',
        PoolConfigurator: '0x86EeC18090D412C24C61F6fe1fa94AD458F876e3',
        ReservesSetupHelper: '0x0aCE43a9DA33cA4c3D69D582E70D2Bd84a5a3f19',
        ACLManager: '0x6C4A080556E003Ed0628024dB814A83164E17891',
        AmplyOracle: '0xF43E78E5F1204c5cAB40Dd2825f1bee9451e267e',
        L2Encoder: '0x5F3e99759962018e4c371128D3cA32E8E5FfE66A',
        EmissionManager: '0x6806b39B7A63c4FbED56B4680f86142455Cc552B',
        RewardsController: '0x35F8fddF1d4323423D80FB3B2f3C9847c9924a2b',
        WrappedTokenGatewayV3: '0xC273d6Df2C9b7e337Eb1dDc0067cE778bb8D3955',
        WalletBalanceProvider: '0x2Ce4531CB531B655006D79cc4D3FBc447d2F5913',
        UiIncentiveDataProviderV3: '0x4BEC5cf1408fffcC2C65fe1061fd7abd22d1BEC8',
        UiPoolDataProviderV3: '0xCbD2125264e9F69437845Ea52FB9d23DD31B5fB5',
        wzkCRO: '0xC1bF55EE54E16229d9b369a5502Bfe5fC9F20b6d',
        vUSD: '0x5b91e29Ae5A71d9052620Acb813d5aC25eC7a4A2',
        vETH: '0x271602A97027ee1dd03b1E6e5dB153eB659A80b1',
        wBTC: '0xd65e5dba71231d35a5802ba83dc6cb6746c9758d',
        ETH: '0x898B3560AFFd6D955b1574D87EE09e46669c60eA',
        CRO: '0xBCaA34FF9D5BFD0d948b18Cf6Bf39a882F4a1cBD',
        USDC: '0xaa5b845F8C9c047779bEDf64829601d8B264076c',
        MOON: '0xc80b437c4c0f9c74cfb2e2d063a58ffb4f82d4f0',
        'Fake Moon': '',
        H2: '0xcaf2fd3f47e7f46e99f74be579b2cc2233f33ef8',
        Paymaster: '0xF809566a93CB882E5055A815bEbe57F8aA6ED9F9',
        ExtraYieldDistributeATokenvUSD: '0x297619e9ac736aa2238d1BFe6c78114714D86a0C',
        ExtraYieldDistributeATokenvETH: '0x33244E002F34DD2af4003D1a8829D48f5EA2B9bE',
    },
    [ChainId.CRONOS_ZKEVM_TESTNET]: {
        CronosId: '',
        PoolAddressesProviderRegistry: '0x2e56E521D9F59d401204b7B412ad400F1f622b72',
        PreAmplyToken: '0x32903c228a33a26fBd2FD87b191F8d24E89d8A99',
        Amply: '0xAaFFDb368DFD44b517Bc387eB252591FF4456BE4',
        AmplyVault: '0x199Ba528eA7836F62a271184f1015A8838D4F6b2',
        AmplyVesting: '0x556ED5A01B42bBa7a5AE4535d7d84696922ac275',
        PoolAddressesProvider: '0xe00003ac954566189B174B85D9D706ac5029ACBe',
        AmplyProtocolDataProvider: '0xD0b0AD37150052B676B599c08e0fA94A7A65b3bd',
        PoolConfigurator: '0x05630610458B0DbfE8b12077933Dc4982C055887',
        ReservesSetupHelper: '0x5da44e0C4891a5F07B550976b8e185082d9e65c8',
        ACLManager: '0x33C52847c5a55DD0359AdA791B0580E1fA39D5F8',
        AmplyOracle: '0x2Da74e789C934f68FDd17b8ed08b142BC5015520',
        L2Encoder: '0xFA39E25A67164192426FaD250e360171182FeC20',
        EmissionManager: '0x5b7C3091918f1701e6221F95967e6e25d77B8db9',
        RewardsController: '0x2f9480dE83b77CAf062C329B04eD7Afb5D952D7b',
        WrappedTokenGatewayV3: '0xF8832219beCf38a26360CEc85803B1A48BBd7Abf',
        WalletBalanceProvider: '0x9833022C2D46408C5fE526022758b157c1BE3fA0',
        UiIncentiveDataProviderV3: '0x779c16548A4d64C67Ea362D19ceB9B06db25A872',
        UiPoolDataProviderV3: '0xbba5C7E16768fD733F943483F972736aB8aB6959',
        wzkCRO: '0xeD73b53197189BE3Ff978069cf30eBc28a8B5837',
        vUSD: '0x9553dA89510e33BfE65fcD71c1874FF1D6b0dD75',
        vETH: '0x16a9Df93DEc0A559CdBAC00cB9E3a1BA91Bf6906',
        wBTC: '',
        ETH: '0x11c3089837549884aa9876ab726c892ed10d263d',
        CRO: '0x08A6E713f6A9Baa9af5170729448F286e5cc6a2a',
        USDC: '0xcD22FEc4653544ac8CA6971Fbc553C9cd15fFad1',
        MOON: '',
        'Fake Moon': '0x99F1Def2a069ca6CCc9a2EcF565521c29B84FB10',
        H2: '0x7c1dA4D7Ea2E6DAEE44052aD8764548A83b5F2bE',
        Paymaster: '0x3D874b4CEF0f36a44D5fd5FF69FE0c9F44397087',
        ExtraYieldDistributeATokenvUSD: '0x7feF2CA05f2b970A8b24EdC133584EAe4fb5153D',
        ExtraYieldDistributeATokenvETH: '0x1D8440134dE4bA86aA9266E02733F3BA09762310',
    },
};
export const getContractAddress = (chainId, contractName) => {
    return ADDRESS_BY_CHAIN[chainId][contractName];
};
export const getEnsAddress = (chainId) => {
    switch (chainId) {
        case ChainId.CRONOS_ZKEVM_MAINNET:
        case ChainId.CRONOS_ZKEVM_TESTNET:
            return getContractAddress(chainId, 'CronosId');
        default:
            throw new Error('unreachable');
    }
};
