import type { ContractAddressMap, SDK } from '@amply-app/sdk';
import { useMutation } from '@tanstack/react-query';
import { tokenMap } from '@ui/components/TokenInput/constants';
import { getZyfiData } from '@ui/utils';
import type { Fraction } from 'bi-fraction';
import type { Signer } from 'zksync-ethers';

import { INTEREST_RATE_MODE } from '../data/constants';
import { approveIfNeeded } from '../data/utils';
import { getAssetSetup, useChainData } from './useChainData';

const repayETH = async (
  repayContext: {
    sdk: SDK;
    browserSigner: Signer;
    poolAddressesProvider: string;
    amount: Fraction;
    decimals: bigint;
    account: string;
  },
  gasTokenAddress?: string,
) => {
  const {
    sdk,
    browserSigner,
    poolAddressesProvider,
    amount,
    decimals,
    account,
  } = repayContext;

  const wrappedTokenGateway = sdk.getWrappedTokenGatewayV3(browserSigner);
  const gasLimit = await wrappedTokenGateway.repayETH.estimateGas(
    poolAddressesProvider,
    amount.shl(Number(decimals)).quotient,
    INTEREST_RATE_MODE,
    account,
    {
      value: amount.shl(Number(decimals)).quotient,
    },
  );

  if (gasTokenAddress) {
    const functionData = wrappedTokenGateway.interface.encodeFunctionData(
      'repayETH',
      [
        poolAddressesProvider,
        amount.shl(Number(decimals)).quotient,
        INTEREST_RATE_MODE,
        account,
      ],
    );
    const data = await getZyfiData({
      gasLimit,
      fromAddress: account,
      toAddress: await wrappedTokenGateway.getAddress(),
      gasTokenAddress,
      functionData,
      value: amount.shl(Number(decimals)).quotient,
    });
    const tx = await browserSigner.sendTransaction(data.txData);
    return await tx.wait();
  }

  const tx = await wrappedTokenGateway.repayETH(
    poolAddressesProvider,
    amount.shl(Number(decimals)).quotient,
    INTEREST_RATE_MODE,
    account,
    {
      value: amount.shl(Number(decimals)).quotient,
    },
  );
  return await tx.wait();
};

const repayToken = async (
  repayContext: {
    sdk: SDK;
    browserSigner: Signer;
    poolAddress: string;
    assetAddress: string;
    amount: Fraction;
    decimals: bigint;
    account: string;
  },
  gasTokenAddress?: string,
) => {
  const {
    sdk,
    browserSigner,
    poolAddress,
    assetAddress,
    amount,
    decimals,
    account,
  } = repayContext;

  const l2Pool = sdk.getL2Pool(poolAddress, browserSigner);
  const gasLimit = await l2Pool[
    'repay(address,uint256,uint256,address)'
  ].estimateGas(
    assetAddress,
    amount.shl(Number(decimals)).quotient,
    INTEREST_RATE_MODE,
    account,
  );

  if (gasTokenAddress) {
    const functionData = l2Pool.interface.encodeFunctionData(
      'repay(address,uint256,uint256,address)',
      [
        assetAddress,
        amount.shl(Number(decimals)).quotient,
        INTEREST_RATE_MODE,
        account,
      ],
    );
    const data = await getZyfiData({
      gasLimit,
      fromAddress: account,
      toAddress: await l2Pool.getAddress(),
      gasTokenAddress,
      functionData,
    });
    const tx = await browserSigner.sendTransaction(data.txData);
    return await tx.wait();
  }

  const tx = await l2Pool['repay(address,uint256,uint256,address)'](
    assetAddress,
    amount.shl(Number(decimals)).quotient,
    INTEREST_RATE_MODE,
    account,
  );
  return await tx.wait();
};

const calculateRepayAmount = (
  amount: Fraction,
  isMaxAmount: boolean,
): Fraction => {
  if (!isMaxAmount) return amount;
  return amount.gte(0.005) ? amount.add(amount.mul(0.001)) : amount.add(0.01);
};

export const useRepayMutation = () => {
  const {
    account,
    chainId,
    provider,
    connectorProvider,
    gasTokenSymbol,
    gasTokenAddress,
  } = useChainData();

  return useMutation(
    async ({
      amount,
      tokenSymbol,
      isMaxAmount,
    }: {
      amount: Fraction;
      tokenSymbol: keyof ContractAddressMap | 'zkCRO';
      isMaxAmount: boolean;
    }) => {
      if (!chainId || !account || !provider || !connectorProvider) return;

      const { browserSigner, sdk, assetAddress, poolAddress, token, decimals } =
        await getAssetSetup(connectorProvider, chainId, tokenSymbol);

      const txAmount = calculateRepayAmount(amount, isMaxAmount);

      if (tokenSymbol === tokenMap.zkCRO) {
        const poolAddressesProvider = sdk.getContractAddress(
          'PoolAddressesProvider',
        );
        return await repayETH(
          {
            sdk,
            browserSigner,
            poolAddressesProvider,
            amount: txAmount,
            decimals,
            account,
          },
          gasTokenSymbol !== tokenMap.zkCRO ? gasTokenAddress : undefined,
        );
      }

      await approveIfNeeded({
        account,
        spender: poolAddress,
        amount: amount.shl(Number(decimals)).quotient,
        token,
        gasTokenAddress,
        gasTokenSymbol,
      });

      return await repayToken(
        {
          sdk,
          browserSigner,
          poolAddress,
          assetAddress,
          amount: txAmount,
          decimals,
          account,
        },
        gasTokenSymbol !== tokenMap.zkCRO ? gasTokenAddress : undefined,
      );
    },
  );
};
