import { type ERC20, getContractAddress } from '@amply-app/sdk';
import { currentWallet } from '@amply-app/wallet';
import { tokenMap } from '@ui/components/TokenInput/constants';
import { LTV_UNIT } from '@ui/config/sc';
import { getZyfiData } from '@ui/utils';
import { getChainId } from '@ui/utils/getChainId';
import { Fraction, RoundingMode } from 'bi-fraction';
import { BrowserProvider } from 'zksync-ethers';

import { MOCK_ZKCRO_ADDRESS } from './constants';
import type { UseAllPoolsData } from './useAllPoolsData';
import type { EModeCategoryData } from './useEMode';
import type { UserReserveData } from './useUserReservesData';

const { getConnector } = currentWallet;

export const approveIfNeeded = async ({
  account,
  token,
  spender,
  amount,
  gasTokenAddress,
  gasTokenSymbol,
}: {
  account: string;
  token: ERC20;
  spender: string;
  amount: bigint;
  gasTokenAddress: string;
  gasTokenSymbol: string;
}) => {
  const allowance = await token.allowance(account, spender);

  const connectorProvider = getConnector().provider;
  const browserSigner = await new BrowserProvider(
    connectorProvider!,
  ).getSigner();
  if (allowance < amount) {
    if (gasTokenSymbol === tokenMap.zkCRO) {
      const approvalTx = await token.approve(spender, amount);
      return await approvalTx.wait();
    }
    // using zyfi paymaster
    const gasLimit = await token.approve.estimateGas(spender, amount);
    const functionData = token.interface.encodeFunctionData('approve', [
      spender,
      amount,
    ]);
    const data = await getZyfiData({
      gasLimit,
      fromAddress: account,
      toAddress: await token.getAddress(),
      gasTokenAddress,
      functionData,
    });
    const tx = await browserSigner.sendTransaction(data.txData);
    return await tx.wait();
  }
};

export const checkZKCROAddress = (tokenAddress?: string) => {
  if (tokenAddress === MOCK_ZKCRO_ADDRESS) {
    return getContractAddress(getChainId(), 'wzkCRO').toLowerCase();
  }
  return tokenAddress;
};
export const compareZKCROAddress = (tokenAddress?: string) => {
  if (tokenAddress === MOCK_ZKCRO_ADDRESS) {
    return getContractAddress(getChainId(), 'wzkCRO');
  }
  return tokenAddress;
};

export const isHealthFactorInWarning = (
  healthFactor: Fraction = Fraction.ZERO,
) => healthFactor.gte(Fraction.ONE) && healthFactor.lt(new Fraction(3));

export const formatAddressForNativeToken = (tokenAddress?: string) =>
  tokenAddress === getContractAddress(getChainId(), 'wzkCRO')
    ? MOCK_ZKCRO_ADDRESS
    : tokenAddress;

export enum LTVType {
  HealthFactor,
  AvailableBorrow,
}

const getCurrentLTV = (
  userEModeCategoryData: EModeCategoryData,
  poolData: UseAllPoolsData | undefined,
  type: LTVType,
) => {
  let currentLTV;
  if (
    userEModeCategoryData.id.neq(0) &&
    poolData?.eModeCategoryId.eq(userEModeCategoryData.id)
  ) {
    currentLTV =
      type === LTVType.HealthFactor
        ? userEModeCategoryData.liquidationThreshold
        : userEModeCategoryData.ltv;
  } else {
    currentLTV =
      type === LTVType.HealthFactor
        ? poolData?.reserveLiquidationThreshold
        : poolData?.baseLTVasCollateral;
  }
  return currentLTV;
};

const formatLTV = (ltv: Fraction) => {
  return new Fraction(
    ltv.toFixed(LTV_UNIT, {
      roundingMode: RoundingMode.ROUND_DOWN,
      trailingZeros: false,
    }),
  );
};

export const computeEnableAsCollateralLTV = (
  tokenAddress: string,
  userReservesData: UserReserveData[],
  poolsData: UseAllPoolsData[],
  userEModeCategoryData: EModeCategoryData,
  type: LTVType,
) => {
  let numerator = Fraction.ZERO;
  let denominator = Fraction.ZERO;
  userReservesData.forEach((userReserveData) => {
    if (
      userReserveData.asCollateral ||
      userReserveData.tokenAddress === compareZKCROAddress(tokenAddress)
    ) {
      const totalSupplyUSD = userReserveData.aTokenBalance.mul(
        userReserveData.tokenUSDPrice,
      );
      const poolData = poolsData.find(
        (pool) =>
          compareZKCROAddress(pool.tokenAddress) ===
          userReserveData.tokenAddress,
      );
      const currentLTV = getCurrentLTV(userEModeCategoryData, poolData, type);

      numerator = currentLTV
        ? currentLTV.mul(totalSupplyUSD).add(numerator)
        : Fraction.ZERO;
      denominator = totalSupplyUSD.add(denominator);
    }
  });
  return denominator.gt(Fraction.ZERO)
    ? formatLTV(numerator.div(denominator))
    : Fraction.ZERO;
};

export const computeDisableAsCollateralLTV = (
  tokenAddress: string,
  userReservesData: UserReserveData[],
  poolsData: UseAllPoolsData[],
  userEModeCategoryData: EModeCategoryData,
  type: LTVType,
) => {
  let numerator = Fraction.ZERO;
  let denominator = Fraction.ZERO;
  userReservesData.forEach((userReserveData) => {
    if (
      userReserveData.asCollateral &&
      userReserveData.tokenAddress !== compareZKCROAddress(tokenAddress)
    ) {
      const totalSupplyUSD = userReserveData.aTokenBalance.mul(
        userReserveData.tokenUSDPrice,
      );
      const poolData = poolsData.find(
        (pool) =>
          compareZKCROAddress(pool.tokenAddress) ===
          userReserveData.tokenAddress,
      );
      const currentLTV = getCurrentLTV(userEModeCategoryData, poolData, type);

      numerator = currentLTV
        ? currentLTV.mul(totalSupplyUSD).add(numerator)
        : Fraction.ZERO;
      denominator = totalSupplyUSD.add(denominator);
    }
  });
  return denominator.gt(Fraction.ZERO)
    ? formatLTV(numerator.div(denominator))
    : Fraction.ZERO;
};

export const computeSupplyLTV = (
  tokenAddress: string,
  userReservesData: UserReserveData[],
  poolsData: UseAllPoolsData[],
  userEModeCategoryData: EModeCategoryData,
  USD: Fraction,
  type: LTVType,
) => {
  let numerator = Fraction.ZERO;
  let denominator = Fraction.ZERO;
  userReservesData.forEach((userReserveData) => {
    if (
      userReserveData.asCollateral ||
      (userReserveData.tokenAddress === compareZKCROAddress(tokenAddress) &&
        userReserveData.aTokenBalance.eq(0))
    ) {
      let totalSupplyUSD = userReserveData.aTokenBalance.mul(
        userReserveData.tokenUSDPrice,
      );
      totalSupplyUSD =
        userReserveData.tokenAddress === compareZKCROAddress(tokenAddress)
          ? totalSupplyUSD.add(USD)
          : totalSupplyUSD;
      const poolData = poolsData.find(
        (pool) =>
          compareZKCROAddress(pool.tokenAddress) ===
          userReserveData.tokenAddress,
      );
      const currentLTV = getCurrentLTV(userEModeCategoryData, poolData, type);

      numerator = currentLTV
        ? currentLTV.mul(totalSupplyUSD).add(numerator)
        : Fraction.ZERO;
      denominator = totalSupplyUSD.add(denominator);
    }
  });
  return denominator.gt(Fraction.ZERO)
    ? formatLTV(numerator.div(denominator))
    : Fraction.ZERO;
};

export const computeWithdrawLTV = (
  tokenAddress: string,
  userReservesData: UserReserveData[],
  poolsData: UseAllPoolsData[],
  userEModeCategoryData: EModeCategoryData,
  USD: Fraction,
  type: LTVType,
) => {
  let numerator = Fraction.ZERO;
  let denominator = Fraction.ZERO;
  userReservesData.forEach((userReserveData) => {
    if (
      userReserveData.asCollateral ||
      userReserveData.tokenAddress === compareZKCROAddress(tokenAddress)
    ) {
      let totalSupplyUSD = userReserveData.aTokenBalance.mul(
        userReserveData.tokenUSDPrice,
      );
      totalSupplyUSD =
        userReserveData.tokenAddress === compareZKCROAddress(tokenAddress)
          ? totalSupplyUSD.sub(USD)
          : totalSupplyUSD;
      const poolData = poolsData.find(
        (pool) =>
          compareZKCROAddress(pool.tokenAddress) ===
          userReserveData.tokenAddress,
      );
      const currentLTV = getCurrentLTV(userEModeCategoryData, poolData, type);

      numerator = currentLTV
        ? currentLTV.mul(totalSupplyUSD).add(numerator)
        : Fraction.ZERO;
      denominator = totalSupplyUSD.add(denominator);
    }
  });
  return denominator.gt(Fraction.ZERO)
    ? formatLTV(numerator.div(denominator))
    : Fraction.ZERO;
};

export const computeDisableEModeLTV = (
  userReservesData: UserReserveData[],
  poolsData: UseAllPoolsData[],
) => {
  let numerator = Fraction.ZERO;
  let denominator = Fraction.ZERO;
  userReservesData.forEach((userReserveData) => {
    if (userReserveData.asCollateral) {
      const totalSupplyUSD = userReserveData.aTokenBalance.mul(
        userReserveData.tokenUSDPrice,
      );
      const poolData = poolsData.find(
        (pool) =>
          compareZKCROAddress(pool.tokenAddress) ===
          userReserveData.tokenAddress,
      );
      const reserveLiquidationThreshold = poolData?.reserveLiquidationThreshold;

      numerator = reserveLiquidationThreshold
        ? reserveLiquidationThreshold.mul(totalSupplyUSD).add(numerator)
        : Fraction.ZERO;
      denominator = totalSupplyUSD.add(denominator);
    }
  });

  return denominator.gt(Fraction.ZERO)
    ? formatLTV(numerator.div(denominator))
    : Fraction.ZERO;
};

export const computeEnableEModeLTV = async (
  userReservesData: UserReserveData[],
  poolsData: UseAllPoolsData[],
  targetEModeCategory: {
    id: number;
    reserveLiquidationThreshold: Fraction;
  },
) => {
  let numerator = Fraction.ZERO;
  let denominator = Fraction.ZERO;
  for (const userReserveData of userReservesData) {
    if (userReserveData.asCollateral) {
      const totalSupplyUSD = userReserveData.aTokenBalance.mul(
        userReserveData.tokenUSDPrice,
      );
      const poolData = poolsData.find(
        (pool) =>
          compareZKCROAddress(pool.tokenAddress) ===
          userReserveData.tokenAddress,
      );

      let reserveLiquidationThreshold;

      if (poolData?.eModeCategoryId.eq(targetEModeCategory.id)) {
        reserveLiquidationThreshold =
          targetEModeCategory.reserveLiquidationThreshold;
      } else {
        reserveLiquidationThreshold = poolData?.reserveLiquidationThreshold;
      }
      numerator = reserveLiquidationThreshold
        ? reserveLiquidationThreshold.mul(totalSupplyUSD).add(numerator)
        : Fraction.ZERO;
      denominator = totalSupplyUSD.add(denominator);
    }
  }
  return denominator.gt(Fraction.ZERO)
    ? formatLTV(numerator.div(denominator))
    : Fraction.ZERO;
};
