import type { SDK } from '@amply-app/sdk';
import { getContractAddress } from '@amply-app/sdk';
import { useMutation } from '@tanstack/react-query';
import { tokenMap } from '@ui/components/TokenInput/constants';
import { getZyfiData } from '@ui/utils';
import { Fraction } from 'bi-fraction';
import { MaxInt256 } from 'ethers';
import type { Signer } from 'zksync-ethers';

import { INTEREST_RATE_MODE, REFERRAL_CODE } from '../data/constants';
import { type UseAllPoolsData } from '../data/useAllPoolsData';
import { getTokenSetup, useChainData } from './useChainData';

const approveDelegationIfNeeded = async (
  sdk: SDK,
  browserSigner: Signer,
  account: string,
  gatewayAddress: string,
  wzkCROVarDebtToken: string,
  amount: Fraction,
  gasTokenAddress?: string,
) => {
  const borrowAllowance = await sdk
    .getVariableDebtToken(wzkCROVarDebtToken, browserSigner)
    .borrowAllowance(account, gatewayAddress);

  if (new Fraction(borrowAllowance).lt(amount)) {
    const variableDebtToken = sdk.getVariableDebtToken(
      wzkCROVarDebtToken,
      browserSigner,
    );

    const approvalGasLimit =
      await variableDebtToken.approveDelegation.estimateGas(
        gatewayAddress,
        MaxInt256,
      );

    if (gasTokenAddress) {
      // Path 1: Using gasTokenAddress for paymaster
      const functionData = variableDebtToken.interface.encodeFunctionData(
        'approveDelegation',
        [gatewayAddress, MaxInt256],
      );
      const data = await getZyfiData({
        gasLimit: approvalGasLimit,
        fromAddress: account,
        toAddress: await variableDebtToken.getAddress(),
        gasTokenAddress,
        functionData,
      });
      const approvalTx = await browserSigner.sendTransaction(data.txData);
      await approvalTx.wait();
    } else {
      // Path 2: Direct approval without paymaster
      const approvalTx = await variableDebtToken.approveDelegation(
        gatewayAddress,
        MaxInt256,
      );
      await approvalTx.wait();
    }
  }
};

const borrowETH = async (
  sdk: SDK,
  browserSigner: Signer,
  poolAddress: string,
  amount: Fraction,
  decimals: bigint,
  account: string,
  gasTokenAddress?: string,
) => {
  const wrappedTokenGateway = sdk.getWrappedTokenGatewayV3(browserSigner);
  const gasLimit = await wrappedTokenGateway.borrowETH.estimateGas(
    poolAddress,
    amount.shl(Number(decimals)).quotient,
    INTEREST_RATE_MODE,
    REFERRAL_CODE,
  );

  if (gasTokenAddress) {
    // Path 1: Using gasTokenAddress for paymaster
    const functionData = wrappedTokenGateway.interface.encodeFunctionData(
      'borrowETH',
      [
        poolAddress,
        amount.shl(Number(decimals)).quotient,
        INTEREST_RATE_MODE,
        REFERRAL_CODE,
      ],
    );
    const data = await getZyfiData({
      gasLimit,
      fromAddress: account,
      toAddress: await wrappedTokenGateway.getAddress(),
      gasTokenAddress,
      functionData,
    });
    const tx = await browserSigner.sendTransaction(data.txData);
    return await tx.wait();
  } else {
    // Path 2: Direct borrow without paymaster
    const tx = await wrappedTokenGateway.borrowETH(
      poolAddress,
      amount.shl(Number(decimals)).quotient,
      INTEREST_RATE_MODE,
      REFERRAL_CODE,
    );
    return await tx.wait();
  }
};

const borrowToken = async (
  borrowContext: {
    sdk: SDK;
    browserSigner: Signer;
    poolAddress: string;
    tokenAddress: string;
  },
  borrowDetails: {
    amount: Fraction;
    decimals: bigint;
    account: string;
  },
  gasTokenAddress?: string,
) => {
  const { sdk, browserSigner, poolAddress, tokenAddress } = borrowContext;
  const { amount, decimals, account } = borrowDetails;

  const l2Pool = sdk.getL2Pool(poolAddress, browserSigner);
  const gasLimit = await l2Pool[
    'borrow(address,uint256,uint256,uint16,address)'
  ].estimateGas(
    tokenAddress,
    amount.shl(Number(decimals)).quotient,
    INTEREST_RATE_MODE,
    REFERRAL_CODE,
    account,
  );

  if (gasTokenAddress) {
    // Path 1: Using gasTokenAddress for paymaster
    const functionData = l2Pool.interface.encodeFunctionData(
      'borrow(address,uint256,uint256,uint16,address)',
      [
        tokenAddress,
        amount.shl(Number(decimals)).quotient,
        INTEREST_RATE_MODE,
        REFERRAL_CODE,
        account,
      ],
    );
    const data = await getZyfiData({
      gasLimit,
      fromAddress: account,
      toAddress: await l2Pool.getAddress(),
      gasTokenAddress,
      functionData,
    });
    const tx = await browserSigner.sendTransaction(data.txData);
    return await tx.wait();
  } else {
    // Path 2: Direct borrow without paymaster
    const tx = await l2Pool['borrow(address,uint256,uint256,uint16,address)'](
      tokenAddress,
      amount.shl(Number(decimals)).quotient,
      INTEREST_RATE_MODE,
      REFERRAL_CODE,
      account,
    );
    return await tx.wait();
  }
};

export const useBorrowMutation = () => {
  const {
    account,
    chainId,
    provider,
    connectorProvider,
    gasTokenSymbol,
    gasTokenAddress,
  } = useChainData();

  return useMutation(
    async ({
      amount,
      tokenData,
    }: {
      amount: Fraction;
      tokenData: UseAllPoolsData;
    }) => {
      if (!chainId || !account || !provider || !connectorProvider) return;

      const { browserSigner, sdk, tokenAddress, poolAddress, decimals } =
        await getTokenSetup(connectorProvider, tokenData);

      if (gasTokenSymbol !== tokenMap.zkCRO) {
        if (tokenData.symbol === tokenMap.zkCRO) {
          const gatewayAddress = getContractAddress(
            chainId,
            'WrappedTokenGatewayV3',
          );
          await approveDelegationIfNeeded(
            sdk,
            browserSigner,
            account,
            gatewayAddress,
            tokenData.variableDebtToken,
            amount,
            gasTokenAddress,
          );
          return await borrowETH(
            sdk,
            browserSigner,
            poolAddress,
            amount,
            decimals,
            account,
            gasTokenAddress,
          );
        } else {
          return await borrowToken(
            { sdk, browserSigner, poolAddress, tokenAddress: tokenAddress! },
            { amount, decimals, account },
            gasTokenAddress,
          );
        }
      }

      if (tokenData.symbol === tokenMap.zkCRO) {
        const gatewayAddress = getContractAddress(
          chainId,
          'WrappedTokenGatewayV3',
        );
        await approveDelegationIfNeeded(
          sdk,
          browserSigner,
          account,
          gatewayAddress,
          tokenData.variableDebtToken,
          amount,
          gasTokenAddress,
        );
        return await borrowETH(
          sdk,
          browserSigner,
          poolAddress,
          amount,
          decimals,
          account,
          gasTokenAddress,
        );
      } else {
        return await borrowToken(
          { sdk, browserSigner, poolAddress, tokenAddress: tokenAddress! },
          { amount, decimals, account },
          gasTokenAddress,
        );
      }
    },
  );
};
