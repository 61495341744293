import type {
  ModalController,
  ModalOverride,
  TxStatusController,
  TxStatusOverride,
} from '@ui/commonStateController';
import {
  createModalController,
  createTxStatusController,
  TxStatus,
} from '@ui/commonStateController';
import type { StateCreator } from 'zustand';
import { create } from 'zustand';

interface UseEModeModal {
  categoryId?: number;
  setCategoryId: (id: number) => void;
  txText?: string;
  setTxText: (text: string) => void;
}

const createOverride: StateCreator<
  UseEModeModal & ModalOverride & TxStatusOverride
> = (set) => ({
  setCategoryId(categoryId) {
    set({ categoryId });
  },
  setTxText(txText) {
    set({ txText });
  },
  isOpen: false,
  txStatus: TxStatus.Default,
  close() {
    set({
      txStatus: TxStatus.Default,
      isOpen: false,
      txError: undefined,
      txHash: undefined,
      categoryId: undefined,
    });
  },
});

export const useEModeModal = create<
  ModalController & TxStatusController & UseEModeModal
>()((...a) => ({
  ...createModalController(...a),
  ...createTxStatusController(...a),
  ...createOverride(...a),
}));
