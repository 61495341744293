import { Button, Card, CardBody, Flex, Text } from '@chakra-ui/react';
import CardWithBorder from '@ui/components/CardWithBorder';
import { useTokenActions } from '@ui/components/Dashboard/Panels/hooks/useTokenActions';
import InfoTooltip from '@ui/components/InfoTooltip';
import { Num } from '@ui/components/Num';
import { UsdValue } from '@ui/components/Num/UsdValue';
import { Info } from '@ui/components/TextBlocks';
import { useBorrowAPY } from '@ui/hooks/data';
import React from 'react';

import type { CardData } from '../Overview';
import AprSection from './AprSection';
import CardTopSection from './CardTopSection';
import { TokenCards } from './TokenCards';

interface Props {
  data?: CardData[];
}

const BorrowCards = ({ data }: Props) => {
  return (
    <TokenCards data={data} renderCard={(item) => <BorrowCard data={item} />} />
  );
};

const BorrowCard = ({ data }: { data: CardData }) => {
  const { label, symbol, vDebtTokenBalance, tokenUSDPrice } = data;
  const { data: borrowAPY } = useBorrowAPY(data.tokenAddress);
  const { incentiveDetails, handleActionClick, handleMoreClick } =
    useTokenActions({
      data,
      type: 'borrow',
    });

  return (
    <CardWithBorder
      w={'300px'}
      isLargeBorder
      key={symbol}
      color="primary.white"
    >
      <Card>
        <CardBody p={4}>
          <CardTopSection label={label} symbol={symbol} />
          <Flex gap={4} flexDir={'column'}>
            <Info
              title={
                <>
                  <Text>Borrowed</Text>
                  <InfoTooltip label={'interest carrying'} />
                </>
              }
              content={<Num value={vDebtTokenBalance} decimalPlaces={4} />}
              usdContent={
                <UsdValue
                  value={vDebtTokenBalance}
                  price={tokenUSDPrice}
                  decimalPlaces={4}
                />
              }
            />
            <AprSection
              type="borrow"
              tokenAPY={borrowAPY}
              incentiveDetails={incentiveDetails}
            />
          </Flex>

          <Flex mt={5} mb={1.5} justifyContent={'space-between'}>
            <Button
              h={'30px'}
              variant={'outline'}
              onClick={handleActionClick}
              minW={'100px'}
            >
              Repay
            </Button>
            <Button
              h={'30px'}
              variant={'outline'}
              minW={'160px'}
              onClick={handleMoreClick}
            >
              Borrow more
            </Button>
          </Flex>
        </CardBody>
      </Card>
    </CardWithBorder>
  );
};

export default BorrowCards;
