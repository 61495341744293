import { ExpandRight } from '@amply-app/icons';
import type { IUiIncentiveDataProviderV3 } from '@amply-app/sdk/dist/esm/types/contracts/UiIncentiveDataProviderV3';
import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react';
import { ChakraNextLink } from '@ui/components/ChakraNextLink';
import TokenImage from '@ui/components/Markets/IndividualMarket/TokenImage';
import { Num, Percent } from '@ui/components/Num';
import { UsdValue } from '@ui/components/Num/UsdValue';
import { SECONDS_PER_DAY } from '@ui/hooks/data/constants';
import { filterRewardData } from '@ui/utils';
import { Fraction } from 'bi-fraction';
import React from 'react';

export const TokenInfoColumn = ({
  symbol,
  label,
}: {
  symbol: string;
  label?: string;
}) => (
  <Flex gap={'10px'}>
    <TokenImage symbol={symbol} w={'36px'} h={'36px'} />
    <Box>
      <Text>{label}</Text>
      <Text textStyle={'body3'} color={'primary.text2'}>
        {symbol}
      </Text>
    </Box>
  </Flex>
);

export const BalanceColumn = ({
  balance,
  tokenUSDPrice,
}: {
  balance: Fraction;
  tokenUSDPrice: Fraction;
}) => (
  <Box>
    <Num value={balance} textStyle={'h4'} decimalPlaces={4} />
    <UsdValue
      value={balance}
      price={tokenUSDPrice}
      color={'primary.text2'}
      decimalPlaces={4}
    />
  </Box>
);

export const APRColumn = ({
  APY,
  incentiveData,
}: {
  APY?: Fraction;
  incentiveData?: IUiIncentiveDataProviderV3.RewardInfoStructOutput[];
}) => (
  <Box>
    <Percent value={APY} textStyle={'h4'} justifyContent={'flex-end'} />
    <Percent value={APY} textStyle={'body1'} justifyContent={'flex-end'} />
    {filterRewardData(incentiveData).map((details) => (
      <Flex gap={1} key={details[0]} justifyContent={'flex-end'}>
        <Num
          key={details[0]}
          justifyContent={'flex-end'}
          value={new Fraction(details[2])
            .shr(Number(details[6]))
            .mul(SECONDS_PER_DAY)}
          textStyle={'body1'}
          prefix={<Text> ${details[0]}:</Text>}
          suffix={
            <>
              <Text>/day</Text>
              <TokenImage w={'24px'} h={'24px'} symbol={details[0]} />
            </>
          }
        />
      </Flex>
    ))}
  </Box>
);

export const ActionButtons = ({
  symbol,
  primaryAction,
  primaryLabel,
  secondaryAction,
  secondaryLabel,
}: {
  symbol: string;
  primaryAction: () => void;
  primaryLabel: string;
  secondaryAction: () => void;
  secondaryLabel: string;
}) => (
  <Flex justifyContent={'space-between'} mt={4}>
    <Flex px={4} gap={3}>
      <Button
        h={'30px'}
        minW={'95px'}
        variant={'outline'}
        onClick={primaryAction}
      >
        {primaryLabel}
      </Button>
      <Button
        h={'30px'}
        minW={'160px'}
        variant={'outline'}
        onClick={secondaryAction}
      >
        {secondaryLabel}
      </Button>
    </Flex>
    <ChakraNextLink href={`/markets/token/?symbol=${symbol}`}>
      <Button h={'30px'} minW={'100px'} variant={'link'}>
        Market Details
        <Icon as={ExpandRight} boxSize={7} color={'primary.cta'} />
      </Button>
    </ChakraNextLink>
  </Flex>
);
