import { TxStatus } from '@ui/commonStateController';
import { useEModeMutation } from '@ui/hooks/mutation/useEModeMutation';
import type { EthersError } from 'ethers';
import { useCallback } from 'react';

import { useEModeModal } from './useEModeModal';

export const useSetEMode = () => {
  const mutation = useEModeMutation();

  const [setTxStatus, setTxHash, setTxError, setTxText] = useEModeModal((s) => [
    s.setTxStatus,
    s.setTxHash,
    s.setTxError,
    s.setTxText,
  ]);
  return useCallback(
    async (categoryId: number | undefined, eModeLabel?: string) => {
      try {
        setTxStatus(TxStatus.Loading);

        if (categoryId === undefined)
          throw new Error(`EMode categoryId is required`);

        const tx = await mutation.mutateAsync({
          categoryId,
        });
        setTxHash(tx?.hash ?? '');
        if (categoryId === 0) {
          setTxText(`You disabled ${eModeLabel} E-Mode`);
        } else {
          setTxText(`You enabled ${eModeLabel}  E-Mode`);
        }

        setTxStatus(TxStatus.Success);
      } catch (e: unknown) {
        setTxStatus(TxStatus.Failed);
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        setTxError(e as EthersError);
      }
    },
    [setTxStatus, mutation, setTxHash, setTxError, setTxText],
  );
};
