import { currentWallet } from '@amply-app/wallet';
import { useQuery } from '@tanstack/react-query';
import { HEALTH_FACTOR_UNIT, LTV_UNIT } from '@ui/config/sc';
import { RefetchInterval } from '@ui/config/ui';
import {
  HealthFactorType,
  useHealthFactor,
} from '@ui/hooks/data/useHealthFactor';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';
import { MaxUint256 } from 'ethers';
import { isNumber } from 'lodash-es';

import { useAllPoolsData } from './useAllPoolsData';
import { useUserAccountData } from './useUserAccountData';
import { useUserReservesData } from './useUserReservesData';
import { computeEnableEModeLTV } from './utils';

export const useCanEnableEMode = (categoryId: number) => {
  const sdk = getSDK();
  const account = currentWallet.useAccount();
  const { data: userReservesData } = useUserReservesData();

  return useQuery(
    ['canEnableEMode', account, userReservesData, categoryId],
    async () => {
      if (!userReservesData) return false;

      for (const userReserveData of userReservesData) {
        if (userReserveData.vDebtTokenBalance.gt(0)) {
          const assetEModeCategoryId = new Fraction(
            await sdk
              .getAmplyProtocolDataProvider()
              .getReserveEModeCategory(userReserveData.tokenAddress),
          );
          if (isNumber(categoryId) && assetEModeCategoryId.neq(categoryId)) {
            return false;
          }
        }
      }
      return true;
    },
    { enabled: !!account, refetchInterval: RefetchInterval.Normal },
  );
};

export const useCanDisableEMode = () => {
  const account = currentWallet.useAccount();
  const { data: userReservesData } = useUserReservesData();
  const originHealthFactor = useHealthFactor(
    HealthFactorType.DisableEMode,
    Fraction.ZERO,
  );

  return useQuery(
    ['useCanDisableEMode', account, userReservesData, originHealthFactor],
    async () => {
      return originHealthFactor.gt(1);
    },
    { enabled: !!account, refetchInterval: RefetchInterval.Normal },
  );
};

export const useUserEModeCategoryId = () => {
  const sdk = getSDK();
  const account = currentWallet.useAccount();

  return useQuery(
    ['useUserEModeCategoryId', account],
    async () => {
      const poolAddress = await sdk.getPoolAddressesProvider().getPool();
      const userEModeCategoryId = await sdk
        .getL2Pool(poolAddress)
        .getUserEMode(account!);
      return new Fraction(userEModeCategoryId);
    },
    { enabled: !!account },
  );
};

export interface EModeCategoryData {
  id: Fraction;
  ltv: Fraction;
  liquidationThreshold: Fraction;
}

export const useUserEModeCategoryData = () => {
  const sdk = getSDK();
  const account = currentWallet.useAccount();

  return useQuery(
    ['useUserEModeCategoryData', account],
    async () => {
      const poolAddress = await sdk.getPoolAddressesProvider().getPool();
      const userEModeCategoryId = await sdk
        .getL2Pool(poolAddress)
        .getUserEMode(account!);
      const data = await sdk
        .getL2Pool(poolAddress)
        .getEModeCategoryData(userEModeCategoryId);

      return {
        id: new Fraction(userEModeCategoryId),
        ltv: new Fraction(data[0]).shr(LTV_UNIT),
        liquidationThreshold: new Fraction(data[1]).shr(LTV_UNIT),
      };
    },
    { enabled: !!account },
  );
};

export interface EModeToken {
  symbol: string;
  collateral: boolean;
  borrowable: boolean;
}
export interface EModeData {
  id: number;
  ltv: Fraction;
  liquidationThreshold: Fraction;
  liquidationBonus: Fraction;
  priceSource: string;
  label: string;
  tokens: EModeToken[];
  tokenMaxLtv: Fraction;
  healthFactor: Fraction;
}
const MAX_NUM_CATEGORY = 20;
export const useEModes = () => {
  const sdk = getSDK();
  const { data: poolsData } = useAllPoolsData();
  const { data: accountData } = useUserAccountData();
  const { data: userReservesData } = useUserReservesData();

  return useQuery(
    [
      'useEModes',
      JSON.stringify(poolsData),
      JSON.stringify(accountData),
      JSON.stringify(userReservesData),
    ],
    async () => {
      const poolAddress = await sdk.getPoolAddressesProvider().getPool();
      if (!poolsData || !accountData || !userReservesData) {
        return [];
      }
      const eModes = [];
      for (let id = 1; id < MAX_NUM_CATEGORY; id++) {
        const data = await sdk.getL2Pool(poolAddress).getEModeCategoryData(id);
        const eModeLiquidationThreshold = await computeEnableEModeLTV(
          userReservesData,
          poolsData,
          {
            id,
            reserveLiquidationThreshold: new Fraction(data[1]).shr(LTV_UNIT),
          },
        );

        const formattedData: EModeData = {
          id,
          ltv: new Fraction(data[0]).shr(LTV_UNIT),
          liquidationThreshold: new Fraction(data[1]).shr(LTV_UNIT),
          liquidationBonus: new Fraction(data[2]).shr(LTV_UNIT),
          priceSource: data[3],
          label: data[4],
          tokens: poolsData
            .filter((data) => data.eModeCategoryId.eq(id))
            .map((data) => ({
              symbol: data.symbol,
              collateral:
                data.usageAsCollateralEnabled &&
                data.baseLTVasCollateral.neq(0),
              borrowable: data.borrowingEnabled,
            })),
          tokenMaxLtv: poolsData
            .filter((data) => data.eModeCategoryId.eq(id))
            .map((data) => data.baseLTVasCollateral)
            .reduce((pre, cur) => (pre.gt(cur) ? pre : cur), Fraction.ZERO),
          healthFactor: accountData.totalDebtBase.isZero()
            ? new Fraction(MaxUint256).shr(HEALTH_FACTOR_UNIT)
            : accountData.totalCollateralBase
                .mul(eModeLiquidationThreshold)
                .div(accountData.totalDebtBase),
        };
        if (formattedData.ltv.eq(Fraction.ZERO)) {
          break;
        }
        eModes.push(formattedData);
      }
      return eModes;
    },
    {
      enabled: !!accountData && !!poolsData && !!userReservesData,
      staleTime: 1000 * 60 * 5,
    },
  );
};
