import {
  HealthFactorPointBottom,
  HealthFactorPointTop,
} from '@amply-app/icons';
import type { BoxProps } from '@chakra-ui/react';
import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { HEALTH_FACTOR_UNIT } from '@ui/config/sc';
import { Fraction } from 'bi-fraction';
import { MaxUint256 } from 'ethers';
import React, { useCallback } from 'react';

import InfoTooltip from '../InfoTooltip';
import {
  backgrounds,
  backgroundsEnd,
  healthFactorPointerConfig,
  longBarIndexes,
  maxHealthFactor,
  minHealthFactor,
} from './constants';

interface Props extends BoxProps {
  value?: Fraction;
  isGlobal?: boolean;
}

const HealthFactor = ({ value: currentValue, isGlobal, ...props }: Props) => {
  const value = Number(currentValue?.toFixed(2) ?? 0);
  const isMaxValue = currentValue?.eq(
    new Fraction(MaxUint256).shr(HEALTH_FACTOR_UNIT),
  );

  const getHealthFactorConfig = useCallback(() => {
    if (value === maxHealthFactor) {
      return { leftShift: '44%', color: '#98FF7E' };
    }
    const idx = healthFactorPointerConfig.findIndex((m) => value < m.threshold);
    return healthFactorPointerConfig[idx < 0 ? 0 : idx];
  }, [value]);

  return (
    <Box backdropFilter={'blur(2px)'} p={4} bg={'gradient.blur'} {...props}>
      <Flex justifyContent={'space-between'} alignItems={'center'} mb={4.5}>
        <Flex gap={1} alignItems={'center'}>
          <Text textStyle={'body2'} color={'primary.white'}>
            {`${isGlobal ? 'Global ' : ''}Health factor`}
          </Text>
          <InfoTooltip
            label={
              isGlobal
                ? 'Global health factor represents the average debt health of all users on the platform'
                : 'The Health Factor (HF) represents the health of your debt position - the higher the value, the safer your position is from getting liquidated'
            }
          />
        </Flex>
        <Text
          textStyle={'body2'}
          background={getHealthFactorConfig().color}
          color={'transparent'}
          backgroundClip={'text'}
        >
          {isMaxValue ? '∞' : value}
        </Text>
      </Flex>

      <Flex pos={'relative'}>
        <Flex gap={0.5}>
          {backgrounds.map((background, index) => (
            <Box
              key={background.key}
              bg={background.bg}
              w={'7px'}
              h={longBarIndexes.includes(index) ? '47px' : '33px'}
              borderRadius={'5px'}
            />
          ))}
        </Flex>
        <Flex
          mx={'1.5px'}
          w={'100%'}
          h={'33px'}
          background={'url(/images/hf-green-bar.svg)'}
          backgroundRepeat={'space no-repeat'}
          backgroundSize={'8.625px 33px'}
        />
        <Flex gap={0.5}>
          {backgroundsEnd.map((background) => (
            <Box
              key={background.key}
              bg={background.bg}
              w={'7px'}
              h={'33px'}
              borderRadius={'5px'}
            />
          ))}
        </Flex>

        <Flex
          flexDir={'column'}
          pos={'absolute'}
          alignItems={'center'}
          justifyContent={'space-between'}
          left={getHealthFactorConfig().leftShift}
          top={'-8px'}
          h="118%"
          transform={'translateX(-50%)'}
          transition={'left 0.3s'}
        >
          <Icon as={HealthFactorPointTop} />
          <Icon as={HealthFactorPointBottom} />
        </Flex>
      </Flex>
      <Flex gap={4} mt={1}>
        <Text textStyle={'body3'} color={'#FDF880'}>
          &lt; {minHealthFactor}
        </Text>
        <Text textStyle={'body3'} color={'#FDF880'}>
          {minHealthFactor} &le; Caution: &lt; {maxHealthFactor}
        </Text>
        <Text textStyle={'body3'} color={'primary.cta'} ml={'48px'}>
          Healthy &ge; {maxHealthFactor}
        </Text>
      </Flex>
    </Box>
  );
};

export default HealthFactor;
