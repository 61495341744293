import { Box, Flex, Switch, Text } from '@chakra-ui/react';
import { useTokenActions } from '@ui/components/Dashboard/Panels/hooks/useTokenActions';
import { Divider } from '@ui/components/MarketCard';
import { useSupplyAPY } from '@ui/hooks/data';
import React, { Fragment } from 'react';

import {
  CollateralDrawerType,
  useCollateralDrawer,
} from '../CollateralDrawer/hooks';
import type { CardData } from '../Overview';
import type { Props } from './SupplyCards';
import {
  ActionButtons,
  APRColumn,
  BalanceColumn,
  TokenInfoColumn,
} from './TableComponents';

const HEADS = ['Asset', 'Supplied', 'Enabled as collateral', 'APR'];
const COLUMN_STYLES: {
  width: string;
  textAlign: 'left' | 'center' | 'right';
}[] = [
  { width: '30%', textAlign: 'left' },
  { width: '20%', textAlign: 'left' },
  { width: '30%', textAlign: 'center' },
  { width: '20%', textAlign: 'right' },
];
const SupplyRow = ({ data }: { data: CardData }) => {
  const {
    symbol,
    label,
    aTokenBalance,
    asCollateral,
    tokenAddress,
    tokenUSDPrice,
  } = data;
  const { data: supplyAPY } = useSupplyAPY(tokenAddress);
  const {
    incentiveDetails,
    handleActionClick: handleWithdrawClick,
    handleMoreClick: handleSupplyMoreClick,
  } = useTokenActions({
    data,
    type: 'supply',
  });

  const [
    openDrawer,
    setAmount,
    setTokenSymbol,
    setTokenAddress,
    setTokenUSDValue,
    setType,
  ] = useCollateralDrawer((s) => [
    s.open,
    s.setAmount,
    s.setTokenSymbol,
    s.setTokenAddress,
    s.setTokenUSDValue,
    s.setType,
  ]);

  const handleSwitchAsCollateral = () => {
    openDrawer();
    setTokenSymbol(symbol);
    setTokenAddress(tokenAddress);
    setAmount(aTokenBalance);
    setTokenUSDValue(aTokenBalance.mul(tokenUSDPrice));
    setType(
      asCollateral ? CollateralDrawerType.Disable : CollateralDrawerType.Enable,
    );
  };

  return (
    <>
      <Flex>
        <Box {...COLUMN_STYLES[0]}>
          <TokenInfoColumn symbol={symbol} label={label} />
        </Box>
        <Box {...COLUMN_STYLES[1]}>
          <BalanceColumn
            balance={aTokenBalance}
            tokenUSDPrice={tokenUSDPrice}
          />
        </Box>
        <Box {...COLUMN_STYLES[2]}>
          <Switch
            isChecked={asCollateral}
            onChange={handleSwitchAsCollateral}
          />
        </Box>
        <Box {...COLUMN_STYLES[3]}>
          <APRColumn APY={supplyAPY} incentiveData={incentiveDetails} />
        </Box>
      </Flex>
      <ActionButtons
        symbol={symbol}
        primaryAction={handleWithdrawClick}
        primaryLabel="Withdraw"
        secondaryAction={handleSupplyMoreClick}
        secondaryLabel="Supply more"
      />
    </>
  );
};

const SupplyTable = ({ data }: Props) => {
  return (
    <Box minW={'645px'} maxW={'645px'}>
      <Flex px={4} mb={2}>
        {HEADS.map((head, i) => (
          <Box
            key={head}
            w={COLUMN_STYLES[i].width}
            textAlign={COLUMN_STYLES[i].textAlign}
          >
            {head}
          </Box>
        ))}
      </Flex>
      <Box
        bg={'rgba(5, 18, 26, 0.3)'}
        p={'20px 16px'}
        backdropFilter={'blur(2px)'}
      >
        {data?.length === 0 && (
          <Text color={'primary.white'} textAlign={'center'} p={4}>
            No data
          </Text>
        )}
        {data?.map((item, i) => (
          <Fragment key={item.symbol}>
            <SupplyRow data={item} />
            {i !== data.length - 1 && (
              <Box mt={5}>
                <Divider />
              </Box>
            )}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default SupplyTable;
