import { TxStatus } from '@ui/commonStateController';
import type { CardData } from '@ui/components/Dashboard/Overview';
import {
  SupplyAndBorrowType,
  useSupplyAndBorrowModal,
} from '@ui/components/SupplyAndBorrowModal';
import { tokenAddressMap } from '@ui/components/TokenInput/constants';
import {
  useWithdrawAndRepayModal,
  WithdrawOrRepay,
} from '@ui/components/WithdrawAndRepayModal';
import type { UseAllPoolsData } from '@ui/hooks/data';
import { useAllPoolsData, useIncentiveAPY } from '@ui/hooks/data';
import { useMemo } from 'react';

interface TokenActionsProps {
  data: CardData;
  type: 'supply' | 'borrow';
}

export const useTokenActions = ({ data, type }: TokenActionsProps) => {
  const { symbol, tokenAddress, tokenUSDPrice } = data;
  const balance =
    type === 'supply' ? data.aTokenBalance : data.vDebtTokenBalance;
  const isCollateral = type === 'supply' ? data.asCollateral : undefined;

  const { data: poolsData } = useAllPoolsData();
  const tokenData = poolsData?.find((data) => data.symbol === symbol);
  const { data: incentiveData } = useIncentiveAPY();

  const incentiveDetails = useMemo(() => {
    const incentiveTokenIndex = incentiveData?.tokenAddress.findIndex(
      (item) => tokenAddressMap[item.toLowerCase()] === tokenData?.symbol,
    );
    return type === 'supply'
      ? incentiveData?.supplyIncentiveData[incentiveTokenIndex!]
      : incentiveData?.borrowIncentiveData[incentiveTokenIndex!];
  }, [incentiveData, tokenData, type]);

  const handleActionClick = () => {
    useWithdrawAndRepayModal.setState((s) => ({
      ...s,
      isOpen: true,
      type:
        type === 'supply' ? WithdrawOrRepay.Withdraw : WithdrawOrRepay.Repay,
      txStatus: TxStatus.Default,
      tokenSymbol: symbol,
      tokenAddress,
      isCollateral,
      tokenBalance: balance,
      tokenUSDPrice,
    }));
  };

  const handleMoreClick = () => {
    useSupplyAndBorrowModal.setState((s) => ({
      ...s,
      type:
        type === 'supply'
          ? SupplyAndBorrowType.Supply
          : SupplyAndBorrowType.Borrow,
      [type === 'supply' ? 'supplyTokenData' : 'borrowTokenData']:
        poolsData?.find((pool: UseAllPoolsData) => pool.symbol === symbol),
      isOpen: true,
    }));
  };

  return {
    tokenData,
    incentiveDetails,
    handleActionClick,
    handleMoreClick,
  };
};
