import { Box, Flex, Text } from '@chakra-ui/react';
import { useTokenActions } from '@ui/components/Dashboard/Panels/hooks/useTokenActions';
import { Divider } from '@ui/components/MarketCard';
import { useBorrowAPY } from '@ui/hooks/data';
import React, { Fragment } from 'react';

import type { CardData } from '../Overview';
import type { Props } from './SupplyCards';
import {
  ActionButtons,
  APRColumn,
  BalanceColumn,
  TokenInfoColumn,
} from './TableComponents';

const HEADS = ['Asset', 'Borrowed', 'APR'];
const COLUMN_STYLES: {
  width: string;
  textAlign: 'left' | 'center' | 'right';
}[] = [
  { width: '45%', textAlign: 'left' },
  { width: '35%', textAlign: 'left' },
  { width: '20%', textAlign: 'right' },
];
const BorrowRow = ({ data }: { data: CardData }) => {
  const { symbol, label, vDebtTokenBalance, tokenAddress, tokenUSDPrice } =
    data;
  const { data: borrowAPY } = useBorrowAPY(tokenAddress);
  const {
    incentiveDetails,
    handleActionClick: handleRepayClick,
    handleMoreClick: handleBorrowMoreClick,
  } = useTokenActions({
    data,
    type: 'borrow',
  });

  return (
    <>
      <Flex>
        <Box {...COLUMN_STYLES[0]}>
          <TokenInfoColumn symbol={symbol} label={label} />
        </Box>
        <Box {...COLUMN_STYLES[1]}>
          <BalanceColumn
            balance={vDebtTokenBalance}
            tokenUSDPrice={tokenUSDPrice}
          />
        </Box>
        <Box {...COLUMN_STYLES[2]}>
          <APRColumn APY={borrowAPY} incentiveData={incentiveDetails} />
        </Box>
      </Flex>
      <ActionButtons
        symbol={symbol}
        primaryAction={handleRepayClick}
        primaryLabel="Repay"
        secondaryAction={handleBorrowMoreClick}
        secondaryLabel="Borrow more"
      />
    </>
  );
};
const BorrowTable = ({ data }: Props) => {
  return (
    <Box minW={'645px'} maxW={'645px'}>
      <Flex px={4} mb={2}>
        {HEADS.map((head, i) => (
          <Box
            key={head}
            w={COLUMN_STYLES[i].width}
            textAlign={COLUMN_STYLES[i].textAlign}
          >
            {head}
          </Box>
        ))}
      </Flex>
      <Box
        bg={'rgba(5, 18, 26, 0.3)'}
        p={'20px 16px'}
        backdropFilter={'blur(2px)'}
      >
        {data?.length === 0 && (
          <Text color={'primary.white'} textAlign={'center'} p={4}>
            No data
          </Text>
        )}
        {data?.map((item, i) => (
          <Fragment key={item.symbol}>
            <BorrowRow data={item} />
            {i !== data.length - 1 && (
              <Box mt={5}>
                <Divider />
              </Box>
            )}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default BorrowTable;
