import { tokenAddressMap } from '@ui/components/TokenInput/constants';
import type { UseAllPoolsData } from '@ui/hooks/data';
import {
  useAmplyPrice,
  useIncentiveAPY,
  useSupplyAPY,
  useSupplyCap,
  useTokenPrice,
  useTokenSupply,
  useTokenUtilization,
} from '@ui/hooks/data';
import type { ReserveInfoResponse } from '@ui/hooks/data/useReservesHistory';
import useReserveHistory from '@ui/hooks/data/useReservesHistory';
import { filterRewardData } from '@ui/utils';
import { Fraction } from 'bi-fraction';
import { isBefore } from 'date-fns';
import React, { useMemo } from 'react';

import { calculateAPY } from '../utils';
import { Information } from './Information';

interface SupplyPanelProps {
  tokenData: UseAllPoolsData;
  hasIncentiveData: boolean;
}
export const SupplyPanel = ({
  tokenData,
  hasIncentiveData,
}: SupplyPanelProps) => {
  const { data: reserves } = useReserveHistory(tokenData.symbol);
  const { data: tokenSupply } = useTokenSupply(tokenData.aTokenAddress);
  const { data: tokenPrice } = useTokenPrice(tokenData.tokenAddress);
  const { data: amplyPrice } = useAmplyPrice();
  const { data: supplyAPY } = useSupplyAPY(tokenData.tokenAddress);
  const tokenUtilization = useTokenUtilization(tokenData);
  const isSupplyCap = useSupplyCap(tokenData);
  const { data: incentiveData } = useIncentiveAPY();

  const incentiveSupplyDataWithAPY = useMemo(() => {
    const incentiveTokenIndex = incentiveData?.tokenAddress.findIndex(
      (item) => tokenAddressMap[item.toLowerCase()] === tokenData.symbol,
    );

    const incentiveSupplyData =
      incentiveData?.supplyIncentiveData[incentiveTokenIndex!];

    return incentiveSupplyData && incentiveSupplyData.length > 0
      ? filterRewardData(incentiveSupplyData).map((item) => ({
          symbol: item[0],
          apy: calculateAPY(
            item,
            tokenData,
            amplyPrice,
            tokenSupply,
            tokenPrice,
          ),
        }))
      : undefined;
  }, [incentiveData, tokenData, amplyPrice, tokenSupply, tokenPrice]);

  const totalIncentiveDetailsAPY = incentiveSupplyDataWithAPY?.reduce(
    (total, item) => (item.apy ? total.add(item.apy) : total),
    Fraction.ZERO,
  );

  const chartData = useMemo(
    () =>
      reserves
        ?.filter((info: ReserveInfoResponse) =>
          isBefore(
            new Date().setDate(new Date().getDate() - 7),
            new Date(info.timestamp),
          ),
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ?.map((r: any) => ({
          date: new Date(r.timestamp),
          value: r.supplyApyPercent.toFixed(2),
        })),
    [reserves],
  );

  return (
    <Information
      data={{
        symbol: tokenData.symbol,
        tokenAPY: supplyAPY,
        netAPY: totalIncentiveDetailsAPY
          ? supplyAPY?.add(totalIncentiveDetailsAPY)
          : supplyAPY,
        incentiveDetails: incentiveSupplyDataWithAPY,
        chartData,
        tokenAmount: tokenSupply,
        tokenUSDValue: tokenPrice,
        utilization: tokenUtilization,
        isCapReached: isSupplyCap,
        hasIncentiveData: hasIncentiveData,
      }}
      type="supply"
    />
  );
};
