import {
  type ContractAddressMap,
  getContractAddress,
  type SDK,
} from '@amply-app/sdk';
import { currentWallet } from '@amply-app/wallet';
import { usePaymaster } from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { getSDK } from '@ui/utils';
import type { Eip1193Provider } from 'ethers';
import type { Signer } from 'zksync-ethers';
import { BrowserProvider } from 'zksync-ethers';

import type { UseAllPoolsData } from '../data';
import { checkZKCROAddress } from '../data/utils';

const { useAccount, useProvider, useChainId, getConnector } = currentWallet;

export const useChainData = () => {
  const account = useAccount();
  const chainId = useChainId();
  const provider = useProvider();
  const connectorProvider = getConnector().provider;
  const [gasTokenSymbol, gasTokenAddress] = usePaymaster((s) => [
    s.token,
    s.tokenAddress,
  ]);

  return {
    account,
    chainId,
    provider,
    connectorProvider,
    gasTokenSymbol,
    gasTokenAddress,
  };
};

export const getTokenSetup = async (
  connectorProvider: Eip1193Provider,
  tokenData: UseAllPoolsData,
) => {
  const browserSigner: Signer = await new BrowserProvider(
    connectorProvider,
  ).getSigner();
  const sdk: SDK = getSDK();
  const tokenAddress = checkZKCROAddress(tokenData.tokenAddress);
  const poolAddress = await sdk.getPoolAddressesProvider().getPool();

  const token = sdk.getERC20(tokenAddress!, browserSigner);
  const decimals = await token.decimals();

  return {
    browserSigner,
    sdk,
    tokenAddress,
    poolAddress,
    token,
    decimals,
  };
};

export const getAssetSetup = async (
  connectorProvider: Eip1193Provider,
  chainId: number,
  tokenSymbol: keyof ContractAddressMap | 'zkCRO',
) => {
  const browserSigner: Signer = await new BrowserProvider(
    connectorProvider,
  ).getSigner();
  const sdk: SDK = getSDK();

  const assetAddress = getContractAddress(
    chainId,
    tokenSymbol === 'zkCRO' ? 'wzkCRO' : tokenSymbol,
  );
  const poolAddress = await sdk.getPoolAddressesProvider().getPool();

  const token = sdk.getERC20(assetAddress, browserSigner);
  const decimals = await token.decimals();

  return {
    browserSigner,
    sdk,
    assetAddress,
    poolAddress,
    token,
    decimals,
  };
};
