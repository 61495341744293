import { Amplified } from '@amply-app/icons';
import { Box, HStack, Text } from '@chakra-ui/react';

export const Boosted = () => {
  return (
    <Box
      borderColor={'primary.yellow'}
      borderRadius={'17px'}
      borderWidth={'0.5px'}
      gap={'4px'}
      px={'6px'}
      py={'2px'}
      mt={'2px'}
    >
      <HStack>
        <Amplified />
        <Text textStyle={'captionBold'}>{'Amplified'}</Text>
      </HStack>
    </Box>
  );
};
