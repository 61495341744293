import { HEALTH_FACTOR_UNIT } from '@ui/config/sc';
import { Fraction } from 'bi-fraction';
import { MaxUint256 } from 'ethers';
import { useMemo } from 'react';

import { useUserAccountData } from './useUserAccountData';
import type { UserReserveData } from './useUserReservesData';
import { useUserReservesData } from './useUserReservesData';
import { useWeightedAverageCurrentLTV } from './useWeightedAverageCurrentLTV';
import { compareZKCROAddress } from './utils';

export enum HealthFactorType {
  Withdraw,
  Repay,
  Borrow,
  Supply,
  EnableAsCollateral,
  DisableAsCollateral,
  DisableEMode,
}

const calculateHealthFactor = (
  type: HealthFactorType,
  USD: Fraction,
  totalCollateralBase: Fraction | undefined,
  totalDebtBase: Fraction | undefined,
  currentLTV: Fraction | undefined,
  userReservesData: UserReserveData[] | undefined,
  tokenAddress: string | undefined,
): Fraction => {
  if (
    totalDebtBase?.eq(Fraction.ZERO) ||
    (type === HealthFactorType.Repay &&
      totalDebtBase?.sub(USD).lte(Fraction.ZERO))
  ) {
    return new Fraction(MaxUint256).shr(HEALTH_FACTOR_UNIT);
  }

  if (totalCollateralBase && currentLTV && totalDebtBase) {
    switch (type) {
      case HealthFactorType.Withdraw:
      case HealthFactorType.DisableAsCollateral:
        return totalCollateralBase.sub(USD).mul(currentLTV).div(totalDebtBase);
      case HealthFactorType.Repay:
        return totalCollateralBase.mul(currentLTV).div(totalDebtBase.sub(USD));
      case HealthFactorType.EnableAsCollateral:
        return totalCollateralBase.add(USD).mul(currentLTV).div(totalDebtBase);
      case HealthFactorType.Supply: {
        const userReserveData = userReservesData?.find(
          (userReserveData) =>
            userReserveData.tokenAddress === compareZKCROAddress(tokenAddress),
        );

        if (
          userReserveData?.asCollateral ||
          (userReserveData?.tokenAddress ===
            compareZKCROAddress(tokenAddress) &&
            userReserveData?.aTokenBalance.eq(0))
        ) {
          return totalCollateralBase
            .add(USD)
            .mul(currentLTV)
            .div(totalDebtBase);
        } else {
          return totalCollateralBase.mul(currentLTV).div(totalDebtBase);
        }
      }
      case HealthFactorType.Borrow:
      default:
        return totalCollateralBase.mul(currentLTV).div(totalDebtBase);
    }
  }
  return Fraction.ZERO;
};

export const useHealthFactor = (
  type: HealthFactorType,
  USD: Fraction = Fraction.ZERO,
  tokenAddress?: string,
) => {
  const { data } = useUserAccountData();
  const { data: userReservesData } = useUserReservesData();

  const currentLTV = useWeightedAverageCurrentLTV(type, USD, tokenAddress);
  const totalCollateralBase = data?.totalCollateralBase;
  const totalDebtBase = useMemo(() => {
    return type === HealthFactorType.Borrow
      ? data?.totalDebtBase.add(USD)
      : data?.totalDebtBase;
  }, [data, USD, type]);

  const healthFactor = useMemo(() => {
    return calculateHealthFactor(
      type,
      USD,
      totalCollateralBase,
      totalDebtBase,
      currentLTV,
      userReservesData,
      tokenAddress,
    );
  }, [
    totalDebtBase,
    totalCollateralBase,
    currentLTV,
    type,
    USD,
    userReservesData,
    tokenAddress,
  ]);
  return healthFactor;
};
