import { Button, Card, CardBody, Flex, Switch, Text } from '@chakra-ui/react';
import CardWithBorder from '@ui/components/CardWithBorder';
import { useTokenActions } from '@ui/components/Dashboard/Panels/hooks/useTokenActions';
import InfoTooltip from '@ui/components/InfoTooltip';
import { Num } from '@ui/components/Num';
import { UsdValue } from '@ui/components/Num/UsdValue';
import { Info } from '@ui/components/TextBlocks';
import { useSupplyAPY } from '@ui/hooks/data';
import React from 'react';

import {
  CollateralDrawerType,
  useCollateralDrawer,
} from '../CollateralDrawer/hooks';
import type { CardData } from '../Overview';
import AprSection from './AprSection';
import CardTopSection from './CardTopSection';
import { TokenCards } from './TokenCards';

export interface Props {
  data?: CardData[];
}

const SupplyCards = ({ data }: Props) => {
  return (
    <TokenCards data={data} renderCard={(item) => <SupplyCard data={item} />} />
  );
};

const SupplyCard = ({ data }: { data: CardData }) => {
  const { label, symbol, aTokenBalance, asCollateral, tokenUSDPrice } = data;
  const { data: supplyAPY } = useSupplyAPY(data.tokenAddress);
  const { incentiveDetails, handleActionClick, handleMoreClick } =
    useTokenActions({
      data,
      type: 'supply',
    });

  const [
    openDrawer,
    setAmount,
    setTokenSymbol,
    setTokenAddress,
    setTokenUSDValue,
    setType,
  ] = useCollateralDrawer((s) => [
    s.open,
    s.setAmount,
    s.setTokenSymbol,
    s.setTokenAddress,
    s.setTokenUSDValue,
    s.setType,
  ]);

  const handleSwitchAsCollateral = () => {
    openDrawer();
    setTokenSymbol(symbol);
    setTokenAddress(data.tokenAddress);
    setAmount(aTokenBalance);
    setTokenUSDValue(aTokenBalance.mul(tokenUSDPrice));
    setType(
      asCollateral ? CollateralDrawerType.Disable : CollateralDrawerType.Enable,
    );
  };

  return (
    <CardWithBorder
      w={'300px'}
      isLargeBorder
      key={symbol}
      color="primary.white"
    >
      <Card>
        <CardBody p={4}>
          <CardTopSection label={label} symbol={symbol} />
          <Flex gap={4} flexDir={'column'}>
            <Info
              title={
                <>
                  <Text>Supplied</Text>
                  <InfoTooltip label={'interest carrying'} />
                </>
              }
              content={<Num value={aTokenBalance} decimalPlaces={4} />}
              usdContent={
                <UsdValue
                  value={aTokenBalance}
                  price={tokenUSDPrice}
                  decimalPlaces={4}
                />
              }
            />

            <Info
              title={
                <>
                  <Text>{`${
                    asCollateral ? 'Disable' : 'Enable'
                  } as collateral`}</Text>
                  <InfoTooltip
                    label={
                      'Enabling this token as collateral increases Health Factor and lets you borrow against it, while disabling it accomplishes the opposite.'
                    }
                  />
                </>
              }
              content={
                <Switch
                  isChecked={asCollateral}
                  onChange={handleSwitchAsCollateral}
                />
              }
            />

            <AprSection
              type="supply"
              tokenAPY={supplyAPY}
              incentiveDetails={incentiveDetails}
            />
          </Flex>

          <Flex mt={5} mb={1.5} justifyContent={'space-between'}>
            <Button
              h={'30px'}
              variant={'outline'}
              minW={'100px'}
              onClick={handleActionClick}
            >
              Withdraw
            </Button>
            <Button
              h={'30px'}
              minW={'160px'}
              variant={'outline'}
              onClick={handleMoreClick}
            >
              Supply more
            </Button>
          </Flex>
        </CardBody>
      </Card>
    </CardWithBorder>
  );
};

export default SupplyCards;
