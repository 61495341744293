import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

import type { CardData } from '../Overview';

interface TokenCardsProps {
  data?: CardData[];
  renderCard: (item: CardData) => React.ReactNode;
}

export const TokenCards = ({ data, renderCard }: TokenCardsProps) => {
  return (
    <Box overflowX={'auto'} maxW={'calc(100vw - 16px)'} py={1}>
      <Flex
        flexWrap={'nowrap'}
        w={'min-content'}
        gap={4}
        pr={4}
        alignItems={'flex-start'}
      >
        {data?.map((item) => renderCard(item))}
      </Flex>
      {data?.length === 0 && (
        <Text color={'primary.white'} textAlign={'center'} p={4}>
          No data
        </Text>
      )}
    </Box>
  );
};
